import { template as template_91376fd99a504853846b2e6e533cfc1b } from "@ember/template-compiler";
import SortableColumn from "./sortable-column";
const LikesCell = template_91376fd99a504853846b2e6e533cfc1b(`
  <SortableColumn
    @sortable={{@sortable}}
    @number="true"
    @order="likes"
    @activeOrder={{@activeOrder}}
    @changeSort={{@changeSort}}
    @ascending={{@ascending}}
    @name="likes"
  />
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default LikesCell;
