import { template as template_2583f57789e0418fa9c8ed5b58496e9b } from "@ember/template-compiler";
const FKLabel = template_2583f57789e0418fa9c8ed5b58496e9b(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
