import { template as template_9617aa81c16c4986a86b3fc685240468 } from "@ember/template-compiler";
const FKControlMenuContainer = template_9617aa81c16c4986a86b3fc685240468(`
  <li class="form-kit__control-menu-container">
    {{yield}}
  </li>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKControlMenuContainer;
